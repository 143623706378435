import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { Styles } from "./styles/teamSlider.js";
import { testimonial } from "../data/testimonial/testimonial-slider.js";
import { Datas, team } from "../data/testimonial/testimonial-slider.js";
import placeholder from '../assets/images/placeholder.png'
const TestimonialSlider = () => {
  const settings = {
    slidesPerView: 2,
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    spaceBetween: 30,
    watchSlidesVisibility: true,
    pagination: {
      el: ".slider-dot.text-center",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
    },
  };

  return (
    <Styles>
      {/* Testimonial Slider */}
      <section
        className="testimonial-area"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})`,
        }}
      >
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <h4>{testimonial.secTitle}</h4>
              </div>
            </Col>
            <Col md="12" className="testimonial-slider">
              <Swiper {...settings}>
                {team.map((data, i) => (
                  <div  className="slider-item" key={i}>
                    <div style={{height:270}} className="desc">
                      {/* <h5>{data.testimonialTitle}</h5> */}
                      <p style={{ textAlign: "justify" }}>
                        {data.testimonialDesc}
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        {data.subDesc}
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        {data.sincerely}
                      </p>
                    </div>
                    <div className="writer">
                      <img
                      style={{width:110, height:110, borderRadius:100}}
                        src={
                          data?.authorImg ?? placeholder
                        }
                        className="slider-image"
                        alt={data.authorImg}
                      />
                      <h6>{data.authorName}</h6>
                      <p>{data.authorTitle}</p>
                    </div>
                  </div>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default TestimonialSlider;
