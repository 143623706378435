import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import GoogleMap from "./GoogleMap";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/contact.js";

function Contact() {
  useEffect(() => {
    const form = document.getElementById("form_contact");
    const name = document.getElementById("contact_name");
    const email = document.getElementById("contact_email");
    const subject = document.getElementById("contact_subject");
    const message = document.getElementById("contact_message");

    form.addEventListener("submit", formSubmit);

    function formSubmit(e) {
      e.preventDefault();

      const nameValue = name.value.trim();
      const emailValue = email.value.trim();
      const subjectValue = subject.value.trim();
      const messageValue = message.value.trim();

      if (nameValue === "") {
        setError(name, "Name can't be blank");
      } else {
        setSuccess(name);
      }

      if (emailValue === "") {
        setError(email, "Email can't be blank");
      } else if (!isEmail(emailValue)) {
        setError(email, "Not a valid email");
      } else {
        setSuccess(email);
      }

      if (subjectValue === "") {
        setError(subject, "Subject can't be blank");
      } else {
        setSuccess(subject);
      }

      if (messageValue === "") {
        setError(message, "Message can't be blank");
      } else {
        setSuccess(message);
      }
    }

    function setError(input, message) {
      const formControl = input.parentElement;
      const errorMsg = formControl.querySelector(".contact_input-msg");
      formControl.className = "form-control text-left error";
      errorMsg.innerText = message;
    }

    function setSuccess(input) {
      const formControl = input.parentElement;
      formControl.className = "form-control success";
    }

    function isEmail(email) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    }
  });

  return (
    <Styles>
      <div className="main-wrapper contact-page">
        <HeaderTwo />
        <BreadcrumbBox title="Contact Us" />
        <section className="contact-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="contact-form">
                  <div className="form-title">
                    <h4>Get In Touch</h4>
                  </div>
                  <div className="form-box">
                    <form id="form_contact" className="form">
                      <Row>
                        <Col md="6">
                          <p className="form-control">
                            <input
                              type="text"
                              placeholder="Full Name"
                              id="contact_name"
                            />
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="6">
                          <p className="form-control">
                            <input
                              type="email"
                              placeholder="Email Address"
                              id="contact_email"
                            />
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="12">
                          <p className="form-control">
                            <input
                              type="text"
                              placeholder="Subject"
                              id="contact_subject"
                            />
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="12">
                          <p className="form-control">
                            <textarea
                              name="message"
                              id="contact_message"
                              placeholder="Enter Message"
                            ></textarea>
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="12">
                          <button>Send Message</button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div style={{ marginBottom: "30px" }}>
                  <h4 style={{ fontWeight: "600", color: "#333", marginBottom: "20px" }}>Contact Info</h4>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "20px" }}>
                  <div style={{ fontSize: "32px", color: "#28a745", marginRight: "15px" }}>
                    <i className="las la-map-marker"></i>
                  </div>
                  <div>
                    <h5 style={{ fontWeight: "600", color: "#333", marginBottom: "5px" }}>Oman Office</h5>
                    <p style={{ fontSize: "14px", color: "#666", margin: "0" }}>
                      Office 12682/B, Way 7776
                      <br />
                      Al Mabaila, Muscat
                      <br />
                      Sultanate of Oman
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "20px" }}>
                  <div style={{ fontSize: "32px", color: "#28a745", marginRight: "15px" }}>
                    <i className="las la-envelope-open"></i>
                  </div>
                  <div>
                    <h5 style={{ fontWeight: "600", color: "#333", marginBottom: "5px" }}>Email Address</h5>
                    <p style={{ fontSize: "14px", color: "#666", margin: "0" }}>oman@studyconcerns.com</p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "20px" }}>
                  <div style={{ fontSize: "32px", color: "#28a745", marginRight: "15px" }}>
                    <i className="las la-phone"></i>
                  </div>
                  <div>
                    <h5 style={{ fontWeight: "600", color: "#333", marginBottom: "5px" }}>Phone Number</h5>
                    <p style={{ fontSize: "14px", color: "#666", margin: "0" }}>+968 24 543 210</p>
                  </div>
                </div>
              </Col>

              <Col md="4" style={{marginTop:"50px"}}>
                <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "20px" }}>
                  <div style={{ fontSize: "32px", color: "#28a745", marginRight: "15px" }}>
                    <i className="las la-map-marker"></i>
                  </div>
                  <div>
                    <h5 style={{ fontWeight: "600", color: "#333", marginBottom: "5px" }}>Lahore Office</h5>
                    <p style={{ fontSize: "14px", color: "#666", margin: "0" }}>
                      721 Block L, MA Johar Town
                      <br />
                      Lahore, Pakistan
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "20px" }}>
                  <div style={{ fontSize: "32px", color: "#28a745", marginRight: "15px" }}>
                    <i className="las la-envelope-open"></i>
                  </div>
                  <div>
                    <h5 style={{ fontWeight: "600", color: "#333", marginBottom: "5px" }}>Email Address</h5>
                    <p style={{ fontSize: "14px", color: "#666", margin: "0" }}>info@studyconcerns.com</p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "20px" }}>
                  <div style={{ fontSize: "32px", color: "#28a745", marginRight: "15px" }}>
                    <i className="las la-phone"></i>
                  </div>
                  <div>
                    <h5 style={{ fontWeight: "600", color: "#333", marginBottom: "5px" }}>Phone Number</h5>
                    <p style={{ fontSize: "14px", color: "#666", margin: "0" }}>+92 42 378 456 78</p>
                  </div>
                </div>
              </Col>

              <Col md="4" style={{marginTop:"50px"}}>
                <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "20px" }}>
                  <div style={{ fontSize: "32px", color: "#28a745", marginRight: "15px" }}>
                    <i className="las la-map-marker"></i>
                  </div>
                  <div>
                    <h5 style={{ fontWeight: "600", color: "#333", marginBottom: "5px" }}>Gujranwala Office</h5>
                    <p style={{ fontSize: "14px", color: "#666", margin: "0" }}>
                      95-A District Court Rd
                      <br />
                      Gujranwala, Pakistan
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "20px" }}>
                  <div style={{ fontSize: "32px", color: "#28a745", marginRight: "15px" }}>
                    <i className="las la-envelope-open"></i>
                  </div>
                  <div>
                    <h5 style={{ fontWeight: "600", color: "#333", marginBottom: "5px" }}>Email Address</h5>
                    <p style={{ fontSize: "14px", color: "#666", margin: "0" }}>admissions@studyconcerns.com</p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "20px" }}>
                  <div style={{ fontSize: "32px", color: "#28a745", marginRight: "15px" }}>
                    <i className="las la-phone"></i>
                  </div>
                  <div>
                    <h5 style={{ fontWeight: "600", color: "#333", marginBottom: "5px" }}>Phone Number</h5>
                    <p style={{ fontSize: "14px", color: "#666", margin: "0" }}>+92 55 123 4567</p>
                  </div>
                </div>
              </Col>
            </Row>

          </Container>
          <GoogleMap />
        </section>
        <FooterTwo />
      </div>
    </Styles>
  );
}

export default Contact;

