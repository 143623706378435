import React, { useEffect, useState } from "react";
import Datas from "../data/course/filter.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/courseFilter.js";
import { dataList } from "../data/course/filter.js"; // Assuming dataList is your course data
import { colors } from "./common/element/elements.js";

function CourseFilter() {
  const [activeCategory, setActiveCategory] = useState("*");
  const [showAllCourses, setShowAllCourses] = useState(false);

  // Handler for category button click
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  // Filter courses based on active category
  const filteredCourses =
    activeCategory === "*"
      ? dataList
      : dataList.filter((course) => course.targetId === activeCategory);

  return (
    <Styles>
      {/* Course Area */}
      <section className="course-filter">
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <h4>{Datas.secTitle}</h4>
              </div>
            </Col>
            <Col md="12">
              {/* Filter Buttons */}
              <div className="filter-btns text-center">
                <ul className="filter-btn-list list-unstyled list-inline">
                  <li
                    data-target="*"
                    className={activeCategory === "*" ? "active list-inline-item" : "list-inline-item"}
                    onClick={() => handleCategoryClick("*")}
                  >
                    All Courses
                  </li>
                  <li
                    data-target="desi"
                    className={activeCategory === "desi" ? "active list-inline-item" : "list-inline-item"}
                    onClick={() => handleCategoryClick("desi")}
                  >
                    Engineering
                  </li>
                  <li
                    data-target="deve"
                    className={activeCategory === "deve" ? "active list-inline-item" : "list-inline-item"}
                    onClick={() => handleCategoryClick("deve")}
                  >
                    Business
                  </li>
                  <li
                    data-target="seo"
                    className={activeCategory === "seo" ? "active list-inline-item" : "list-inline-item"}
                    onClick={() => handleCategoryClick("seo")}
                  >
                    Information Technology
                  </li>
                  <li
                    data-target="health"
                    className={activeCategory === "health" ? "active list-inline-item" : "list-inline-item"}
                    onClick={() => handleCategoryClick("health")}
                  >
                    Health Science
                  </li>
                  <li
                    data-target="sci"
                    className={activeCategory === "sci" ? "active list-inline-item" : "list-inline-item"}
                    onClick={() => handleCategoryClick("sci")}
                  >
                    Science
                  </li>
                  <li
                    data-target="prog"
                    className={activeCategory === "prog" ? "active list-inline-item" : "list-inline-item"}
                    onClick={() => handleCategoryClick("prog")}
                  >
                    Hospitality & Tourism
                  </li>
                  <li
                    data-target="arts"
                    className={activeCategory === "arts" ? "active list-inline-item" : "list-inline-item"}
                    onClick={() => handleCategoryClick("arts")}
                  >
                    Arts & Design
                  </li>
                </ul>
              </div>

              {/* Course Items */}
              <Row className="filter-items">
                {filteredCourses
                  .slice(0, showAllCourses ? filteredCourses.length : 6)
                  .map((data, i) => (
                    <Col lg="4" md="6" key={i} data-id={data.targetId}>
                      <div className="course-item">
                        <Link to={process.env.PUBLIC_URL + data.courseLink}>
                          <div
                            className="course-image"
                            style={{
                              backgroundImage: `url(${data.imgUrl})`,
                            }}
                          >
                            <div className="author-img d-flex">
                              {/* Optional: Add Author Image and Name here */}
                            </div>
                          </div>
                        </Link>
                        <div className="course-content">
                          <p style={{ height: 60 }} className="heading">
                            <Link to={process.env.PUBLIC_URL + data.courseLink}>
                              {data.courseTitle}
                            </Link>
                          </p>
                          <p
                            style={{
                              textAlign: "justify",
                              height: 115,
                            }}
                            className="desc"
                          >
                            {data.courseDesc}
                          </p>
                          <div className="course-face d-flex justify-content-between">
                            <div className="duration">
                              <p>
                                <i className="las la-clock"></i>120
                              </p>
                            </div>
                            <div className="rating">
                              <ul className="list-unstyled list-inline">
                                <li className="list-inline-item">
                                  <i className="las la-star"></i>
                                </li>
                                <li className="list-inline-item">
                                  <i className="las la-star"></i>
                                </li>
                                <li className="list-inline-item">
                                  <i className="las la-star"></i>
                                </li>
                                <li className="list-inline-item">
                                  <i className="las la-star"></i>
                                </li>
                                <li className="list-inline-item">
                                  <i className="las la-star-half-alt"></i>
                                </li>
                                <li className="list-inline-item">(4.5)</li>
                              </ul>
                            </div>
                            <div className="student">
                              <p>
                                <i className="las la-chair"></i>60
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
            {/* View All Button */}
            <Col md="12" className="text-center">
              <div className="viewall-btn">
                <div onClick={() => setShowAllCourses(!showAllCourses)}>
                  {showAllCourses ? "Less Courses" : "View All Courses"}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default CourseFilter;
